/* ---------- Formulario con estilos verde y redondeado --------- */
/* ---------------------- Version 2 con SASS -------------------- */
/* -------------------------------------------------------------- */
/* -- VMEL: Para usar estos estilos en elementos de formulario,
      solo hay que poner una de las siguientes clases a una
      capa por encima del elemento:

      - "solo-estilo-verde": solo estilos de campos
      - @extend %solo_estilo_verde;
*/
/* -------------------------------------------------------------- */

@import 'variables';


// --------------------------------------
//        *** Solo estilo verde ***
// --------------------------------------

.solo-estilo-verde,
#content .solo-estilo-verde,
%solo_estilo_verde {
	// input[type="text"],
	// input[type="password"] {
	// 	border:2px solid #007B22;
	// 	border-radius:5px;
	// 	padding:0.1em 0.5em;
	// 	box-shadow:1px 1px 4px rgba(0, 0, 0, 0.5) inset;

	// 	@include link-hf {background-color:#007B22;color:white;border-color:#333;}

	// 	.altocontraste & {border-color:white;}

	// 	@include link-hf {
	// 		background-color:map-get($colors, verde-ilusion);
	// 		color:white;
	// 		border-color:#333;

	// 		.altocontraste & {background-color: yellow;border-color:white;}
	// 	}
	// }

	input[type="file"] {max-width: 30em; width: 100%;}

	// Estilo de fieldset para un encabezado. debe contener un span con el texto
	.estilo-fieldset {
		border-bottom: 2px solid map-get($colors, verde-ilusion);
		font-size: 100%;
		position:relative;
		margin-bottom: 2em;

		span {
			color:map-get($colors, verde-ilusion);
			background-color:white;
			margin-left: 1em;
			padding:0.5em;
			text-transform: uppercase;
			position: absolute;
			margin-top: -1em;

			.altocontraste & {background-color:black;color:white;}
		}

		.altocontraste & {border-color:white;}
	}

	select {
		border:2px solid #007B22;
        border-radius:5px;
        // padding:0.1em 0.5em;
        box-shadow:1px 1px 4px rgba(0, 0, 0, 0.5) inset;
        font-family: Avenir Next W01, Arial, FreeSans, sans-serif;

		.altocontraste & {border-color:white;}

		option {
			color:white;
			.altocontraste & {color:white;}
		}

		@include link-hf {
			background-color:#007B22;color:white;border-color:#333;
			.altocontraste & {
				background-color:yellow;
				color:black;
				border-color:white;

				option {color:black;}
			}
		}
	}

	select[multiple] {
		option {
			color:inherit;
		}
	}

	textarea {
		border:2px solid #007B22;
		border-radius:5px;
		padding:0.1em 0.5em;
		box-shadow:1px 1px 4px rgba(0, 0, 0, 0.5) inset;max-width:20.5em;
		width:100%;

		@include link-hf {
			background-color:#007B22;
			color:white;
			border-color:#333;

			&::placeholder {color: white; opacity:1;}
		}

		.altocontraste & {
			border-color:white;
			@include link-hf {border-color:white;}

			&::placeholder {color: white; opacity:1;}
			&:hover::placeholder {color: black; opacity:1;}
			&:active::placeholder {color: black; opacity:1;}
			&:focus::placeholder {color: black; opacity:1;}
		}
		.mobile & {width:100%;}

		&::placeholder {color: #333; opacity:1;}
	}

	input::placeholder {color: #333; opacity:1;}
	input:hover::placeholder {color: white; opacity:1;}
	input:active::placeholder {color: white; opacity:1;}
	input:focus::placeholder {color: white; opacity:1;}

	.altocontraste & {
		input::placeholder {color: white; opacity:1;}
		input:hover::placeholder {color: black; opacity:1;}
		input:active::placeholder {color: black; opacity:1;}
		input:focus::placeholder {color: black; opacity:1;}


		input[type="text"][disabled=""],
		textarea[disabled=""],
		select[disabled=""] {
			border-color:#404040;
			box-shadow: none;

			@include link-haf {background-color: #404040; color:white; }
		}
	}


	// -------------------------------
	/*  *  1170px and below * */
	// -------------------------------
	@media screen and (max-width : 1170px) {
		.escritorio & {

			.fieldInline + .fieldInline + .fieldInline {
				display: block;
				margin-right: 0;
				margin-top:1em;

				label {width: 25%; text-align: right;}
			}
		}
	}

	// -------------------------------
	/*  *  1000px and below * */
	// -------------------------------
	@media screen and (max-width : 1000px) {
		.escritorio & {
			.fieldInline {
				display: block;
				margin-right: 0;

				+ .fieldInline label,
				+ .fieldInline + .fieldInline label {width: 27%;text-align: right;}
			}
		}
	}

	// -------------------------------
	/*  *  767px and below * */
	// -------------------------------
	@media screen and (max-width : 767px) {
		.escritorio & {

			.fieldInline {
				+ .fieldInline label,
				+ .fieldInline + .fieldInline label {width: auto;text-align: left;}
			}

			.formControls {margin: 1em 0;padding-left:0;}
		}
	}

	// -------------------------------
	/*  *  MOBILE * */
	// -------------------------------
	.mobile & {
		.formControls {margin: 1em 0;}
	}
}
