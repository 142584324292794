// --------- ATENCION: ---------------------
// NO USAR tildes ni caracteres extranos en los comentarios
// Esto aniade una linea de chartset al inicio de la css compilada que
// genera un error

// -----------------------------------------------------
/*        Hoja de estilos para la Zona Personal       */
// -----------------------------------------------------

// ------------------------------------------
// ----------- importaciones ----------------
// ------------------------------------------

@import 'variables';
@import 'soloestiloverde';

/* ----------------- Estilos generales ------------------------ */

#formfield-form-widgets-inputemail,
#formfield-form-widgets-inputemailctl {
    width: 25em;
    max-width: 100%;

    @media (max-width: 767px) {width: 100%;}
}

#dashboard {
    font-size: inherit;
}

.dashboard-columns-3 .dashboard-column {
    width: 33.3%;
}

#dashboard-portlets1,
#dashboard-portlets2,
#dashboard-portlets3,
.zonapersonal-grupo {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    padding: 1em 0 0 0;
    align-items: flex-start;


    >.managedPortlet,
    >.dashboardPortlet {
        margin: 0.5em;
        width: 22em;

        @media screen and (max-width : 767px) {
            margin: 0.5em 0;
            width: 100%;
        }
    }
}


.portletwrapper {
    background-color: white;
    padding-right: 1em;
    padding-left: 1em;
}

#dashboard .managedPortlet>.portletHeader {
    cursor: move;
    display: flex;
    justify-content: space-between;

    .managedPortletActions {
        min-width: 4.5rem;
        text-align: right;
    }
}

/* @group dashboard actions */



.portletHeader {
    position: relative;
}

.portletHeader .portletActionsWrapper {
    float: right;
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    background: rgba(245, 250, 254, 0.9);
    border-left: 1px solid #E7ECF0;
    padding: 0.4em 1em 0 0.5em;
}

.portlet:hover .portletHeader .portletActionsWrapper {
    display: block;
}

dl.portlet dt .portletActionsWrapper a,
dl.portlet dt .portletActionsWrapper span {
    background: url(++resource++ftw.dashboard.dragndrop.resources/portlet_actions.png) no-repeat;
    width: 16px;
    min-height: 20px;
    margin-left: 3px;
    display: inline-block;
}

dl.portlet dt .portletActionsWrapper a:first-child {
    margin-left: 0;
}

dl.portlet dt .portletActionsWrapper span.buttonMove {
    background-position: 0 0;
}

dl.portlet dt .portletActionsWrapper span.buttonMove:hover {
    background-position: 0 -50px;
}

dl.portlet dt .portletActionsWrapper a.buttonEdit {
    background-position: 0 -100px;
}

dl.portlet dt .portletActionsWrapper a.buttonEdit:hover {
    background-position: 0 -150px;
}

dl.portlet dt .portletActionsWrapper a.buttonClose {
    background-position: 0 -200px;
}

dl.portlet dt .portletActionsWrapper a.buttonClose:hover {
    background-position: 0 -250px;
}

dl.portlet dt .portletActionsWrapper a.buttonOpen {
    background-position: 0 -300px;
}

dl.portlet dt .portletActionsWrapper a.buttonOpen:hover {
    background-position: 0 -350px;
}

dl.portlet dt .portletActionsWrapper a.buttonRemove {
    background-position: 0 -400px;
}

dl.portlet dt .portletActionsWrapper a.buttonRemove:hover {
    background-position: 0 -450px;
}

/* @end */



span.noEntries {
    width: 20.6em;
    display: block;
    padding: 0.5em;
}

.folded dd,
.folded form {
    display: none;
    visibility: hidden;
    width: 100%;
}


/* Dashboard */


#dashboard-add-portlet form {
    float: left;
    display: block;
}

div#dashboard #dashboard-add-portlet {

    text-align: left;
    padding-top: 1em;
}

body.section-konto div#portal-column-two {
    margin-top: 8.2em;
}

/* Remove dashboard dialog */


.template-dashboard .ui-widget-header,
.template-manage-dashboard .ui-widget-header {
    display: none;
}

#remove-portlet-dialog {
    font-weight: bold;
    font-size: 1.25em;
    padding-top: 2em;
    text-align: center;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    text-align: center;
    float: none;
}


#content #dashboard .dashboardPortlet a.modulo {
    border-bottom: none;
}

#dashboard div.insideWrapper {
    border: 2px solid map-get($colors, verde-ilusion);

    .altocontraste & {
        border-color: white;
    }
}

#dashboard div.insideWrapper span.contador {
    margin: -1.8em 0 0 -2.7em;
    border-radius: 4px;
    padding: 0.2em 0.5em;
    background: #861a29;
    color: white;
    font-weight: bold;
    float: left;
}

#dashboard div.insideWrapper div.portletWrapperInterior {
    padding: 1em;
    word-wrap: break-word;

    ul {
        margin: 0 0 1em 0;

        li:first-child {
            margin-top: 0;
        }
    }

    .listElementoUnico:not(:last-child) {
        margin-bottom: 1em;
    }

    .portletFooter {

        >div:not(:last-child) {
            margin: 1em 0;
        }

        div.discreet div {
            margin-top: 1em;
        }
    }

    .link-subscripciones-item {
        color: #000 !important;

        i {
            color: map-get($colors, verde-ilusion);
        }
    }
}

#dashboard-portlets1 div.insideWrapper h2.modulo {
    background-color: map-get($colors, verde-ilusion);
    display: block;
    color: white;
    padding: 0.5em 1em;
    word-wrap: break-word;
    border-bottom: none;
    margin: 0;
    font-size: 1em;
}

#dashboard .managedPortlet {
    border: 2px dashed #474747;
    background-color: #ECE9EA;
}

#dashboard .managedPortlet div.portletWrapper {
    padding: 0.5rem 0 1.0rem;
    word-wrap: break-word;
}

#dashboard .managedPortletActions .portlet-action {
    display: inline;
}

div.portletAssignments {
    line-height: inherit;
}

div.portletAssignments .managedPortletActions a,
div.portletAssignments .managedPortletActions button {
    color: inherit;
}

#dashboard .blockedPortlet div.portletWrapper {
    opacity: 0.5;
}

#fieldset-cambio-de-correo-electronico,
#formfield-form-widgets-inputresultadospagina {
    @extend %solo_estilo_verde;
}

// Portlet Calendar

#content .portlet.portletCalendar {
    background-color: white;
    border: 1px solid black;
    margin-bottom: 0;

    dt {

        a#calendar-previous,
        a#calendar-next {
            padding: 0 0.5em;

            .altocontraste & {
                border: 1px solid transparent;
            }
        }
    }

    dd {
        margin: 0;

        .weekdays th {
            .altocontraste & {
                background-color: black;
            }
        }

        .event {

            .altocontraste & {
                background-color: black;
            }
        }

        .todayevent {
            border: 2px solid black;

            .altocontraste & {
                background-color: black;
                border-color: yellow;
            }
        }
    }

    a {
        text-decoration: none !important;
    }

    a.tile {
        border-top: 1px solid black;
        display: block;
        padding: 0.3em;
        text-align: center;
    }

    .altocontraste & {
        background-color: transparent;
    }
}

// FIN PORTLET CALENDAR


/* ESTILOS ALTOCONTRASTE */
.altocontraste #dashboard-portlets1 div.insideWrapper h2.modulo {
    background-color: #303030;
    border: none;
}

.altocontraste #dashboard .managedPortlet {
    border-color: white;
    background-color: #202020;
}

.altocontraste #dashboard .portlet-manager div.zona-personal {
    border: none;
}
